import React from "react";
import { Route } from "react-router";
import Loadable from "react-loadable";
import Loading from "../ui-molecules/Loading";

const UserHome = Loadable({
  loader: () => import("../ui-pages/UserHome"),
  loading: Loading
});

const AboutUs = Loadable({
  loader: () =>
    import("../ui-pages/UserHome/components/Content/components/AboutUs"),
  loading: Loading
});

const Login = Loadable({
  loader: () => import("../ui-pages/Login"),
  loading: Loading
});

const lcd = Loadable({
  loader: () => import("../ui-pages/lcd"),
  loading: Loading
});

const MainRoutes = () => {
  return (
    <div>
      <Route exact path="/" component={Login} />
      <Route path="/user-home" component={UserHome} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/lcd" component={lcd} />
    </div>
  );
};

export default MainRoutes;
