export default {
  typography: {
    // In Japanese the characters are usually larger.
    fontSize: 12
  },
  palette: {
    primary: {
      main: "#e8e828",
      contrastText: "#fff"
    },
    secondary: {
      main: "#25273e",
      contrastText: "#fff"
    }
  }
};
