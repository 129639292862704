import * as screenActionTypes from "./actionTypes";
import { prepareFinalObject } from "./utils";

const getUserInfo = () => {
  const userInfo = window.localStorage.getItem("userInfo");
  return userInfo ? JSON.parse(userInfo) : {};
};

const intialState = {
  preparedFinalObject: {
    snackbar: {
      open: false,
      variant: "success",
      message: ""
    },
    spinner: false,
    report: {
      tabs: {
        subscription: {
          from: null,
          to: null,
          pendingSubscriptions: [],
          efficiency: {
            expiredSubFreeCount: 0,
            totalSubFreeCount: 0,
            expiredSubPaidCount: 0,
            totalSubPaidCount: 0
          },
          totalUsers: {
            usersCount: [],
            months: []
          }
        },
        lighting_dashboard: {},
        portfolio: {
          favorite_portfolios: {
            dataSet: { data: [], labels: [] }
          },
          total_portfolios: {
            dataSet: { data: [], labels: [] }
          }
        },
        delivery: {}
      }
    },
    userInfo: getUserInfo()
  }
};

const screenConfiguration = (state = intialState, action) => {
  switch (action.type) {
    case screenActionTypes.PREPARE_FINAL_OBJECT:
      const updatedPreparedFinalObject = prepareFinalObject(
        state.preparedFinalObject,
        action.jsonPath,
        action.value
      );
      return {
        ...state,
        preparedFinalObject: updatedPreparedFinalObject
      };
    default:
      return state;
  }
};

export default screenConfiguration;
